<template>
  <b-modal
    id="modal-quota-detail"
    ref="refModalQuotaDetail"
    title="Thông tin định mức"
    size="lg"
    scrollable
    :hide-footer="true"
    no-close-on-backdrop
    @show="onOpen"
    @hidden="resetModal"
  >

    <div class="d-flex justify-content-center mb-1">
      <b-spinner
        v-if="isLoading"
        class="text-center"
        variant="secondary"
      />
    </div>

    <div v-if="itemLocal && !isLoading">
      <div v-if="itemLocal.items">

        <quota-table

          :data="itemLocal.items"
        />
      </div>

    </div>
    <!-- Body -->

  </b-modal>
</template>

<script>
import {
  BModal,
  BSpinner,
} from 'bootstrap-vue';
import { required } from '@validations';
import formValidation from '@core/comp-functions/forms/form-validation';
import useQuotaDetailModal from './useQuotaDetailModal';
import QuotaTable from '../../fee/detail/QuotaTable.vue';

export default {
  components: {
    BModal,
    BSpinner,
    QuotaTable,
  },
  props: {
    quotaId: {
      type: Number,
      default: 0,
      required: true,
    },
  },
  data() {
    return {
      required,
    };
  },
  setup(props, { emit }) {
    const {
      refFormObserver, getValidationState, resetForm, clearForm,
    } = formValidation();

    const {
      refModalDeleteCashbook,
      itemLocal,
      resetItemLocal,
      resetModal,
      onOpen,
      isLoading,
    } = useQuotaDetailModal(props, emit, refFormObserver);

    return {
      refModalDeleteCashbook,
      itemLocal,
      resetItemLocal,
      resetModal,
      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,
      onOpen,
      isLoading,
    };
  },
};
</script>

<style lang="scss">
  @import "@core/scss/vue/libs/vue-good-table.scss";
  .vgt-wrap {
    .vgt-table {
      thead {
        th {
          font-size: 1rem !important;
          font-weight: 500;
        }
      }
      td {
        font-size: 1rem !important;
        font-weight: 500;
      }
    }
  }
  </style>
